.ourapproachsection {
    
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  margin: auto;
  justify-content: center;
padding: 100px 0 100px 0;
gap: 32px;
    
  }
  .sub-contentchota {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Decreased gap between sub-heading and sub-para */
    
  }
  
  .sub-headingoao {
    display: flex;
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 28px;
color: #FFFFFF;
margin-top: 18px;
margin-bottom: 0px;
align-items: center;


  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
  }
  
  .sub-content {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    align-items: center;
  }
  
  
  .stroke {
    width: 24px;
    height: 2px;
    background-color: #B9E937;
  }
  
  .number {
    font-size: 18px;
font-style: normal;
font-weight: 500;
font-size: 24px;




color: #B9E937;
  }
  
  .sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #FFFFFF;
    margin-bottom: 4px; /* Added margin-bottom to sub-heading */
  }
  
  .sub-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}




@media (max-width: 810px) {
  .ourapproachsection {
    max-width: 714px;
    width: 100%;
    margin: auto;
  }
  
}


@media (max-width: 430px) {

  .ourapproachsection {
    max-width: 302px;
    width: 100%;
    margin: auto;}
    .section-heading {
     
      font-size: 30px;
   
    }
    .number {
     
      font-size: 30px;
   
    }
    .sub-headingoao{
      font-size: 24px;
    }
    .number{
      font-size: 24px;
    }
  }
