.categorysection {
  display: flex;
  max-width: 1000px;
  margin: auto;
  flex-wrap: wrap;
  padding-top: 100px;
  gap: 20px;
  padding-bottom: 60px;
}

.bt {
  display: inline-flex;
  padding: 15px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #181818;
  border: none;
  cursor: pointer;
  outline: none;
}

.bt:hover {
  background-color: #b9e937;
  color: #000000;
}
.bt:active {
  background-color: #b9e937;
  color: #000000;
}
.bt:hover .bts {
  color: #000000;
}

.bts {
  color: #dbd9dd;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}

.categorysectiondown {
  display: flex;
  max-width: 1000px;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-bottom: 120px;
}




.itemonedownone {
  color: #b9e937;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
}

.itemonedowntwo {
  color: #dbd9dd;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}

.itemonedownthree {
  color: #dbd9dd;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28px;
}

.itemonedownfour {
  color: #b9e937;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 28px;
}

.itemone {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #181818;
  width: auto;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.itemonedown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 14px;
  gap: 20px;
  width: 294px;
}



/* Tablet version */
@media (max-width: 810px) {


.categorysection {
  max-width: 714px;
  width: 100%;
  margin: auto;
}
.categorysectiondown{

  max-width: 714px;
  width: 100%;
  margin: auto;
  padding-bottom: 100px;

}

.row{
  max-width: 714px;
  width: 100%;
  margin: auto;
  flex-direction: column;
  padding: 0%;
  gap: 10px;
}
.itemone{
  flex-direction: row;
  width: 100%;
}

.itemoneup img {

  align-items: center;
  justify-content: center;
}
.itemonedown{
  width: 100%;
  gap: 8px;
}
}



    /* Mobile version*/

    @media (max-width: 430px) {

      .categorysection{
        max-width: 302px;
        width: 100%;
        margin: auto;}
        .categorysectiondown {
          display: flex;
          max-width: 1000px;
          margin: auto;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;
          padding-bottom: 120px;
        }
        
        
        
        
        .itemonedownone {
          color: #b9e937;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.16px;
        }
        
        .itemonedowntwo {
          color: #dbd9dd;
          font-size: 20px;
          font-family: Poppins;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.2px;
        }
        
        .itemonedownthree {
          color: #dbd9dd;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 400;
          line-height: 28px;
        }
        
        .itemonedownfour {
          color: #b9e937;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 400;
          line-height: 28px;
        }
        
        .itemone {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          background: #181818;
          width: auto;
        }
        
        .row {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 40px;
        }
        
        .itemonedown {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 14px;
          gap: 20px;
          width: 294px;
        }
        
}
    