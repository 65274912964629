.sectiontsq {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 0%;
  }
  
  /* Rest of the CSS styles remain the same */
  
  .circle1,
  .circle2,
  .circle3 {
    width: 10.67px;
    height: 10.67px;
    border-radius: 50%;
    cursor: pointer;
    background: #ffffff; /* Set the default background color to white */
  }
  
  .circle1.active,
  .circle2.active,
  .circle3.active {
    background: #b9e937; /* Set the active background color */
  }
  
  /* Additional CSS styles for inactive circles */
  .circle1:not(.active),
  .circle2:not(.active),
  .circle3:not(.active) {
    background: #ffffff; /* Set the inactive background color to white */
  }
  
  .carddiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .maindivlower {
    display: flex;
    flex-direction: row;
  }
  
  /* Additional CSS styles for stacking elements in twoa section */
  .twoa,
  .twob,
  .twoc {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
  }

  .bh{

    margin-top: 22px;
    margin-bottom: 10px;
  }
  .carddiv:hover .bh{

    color: #b9e937;
    cursor: pointer;
  }
  .carddiv:hover{
    cursor: pointer;
  }


  @media (max-width: 810px) {
    .sectiontsq {
      max-width: 714px;
      width: 100%;
      margin: auto;
      padding-bottom: 100px;
    }
  
    .partion2 {
      flex-direction: column;
      align-items: center;
    }
  
    .carddiv {
      width: calc(50%); /* Adjust the width as per your requirement */
      margin-left: 8px;
      margin-right: 8px;
    }
  .bh{
    width: 320px;
  }
    .maindivlower {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
    }
  }


  @media (max-width: 430px) {
    .sectiontsq {
      max-width: 302px;
      width: 100%;
      margin: auto;
    }
    
    .maindivlower {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .twoa,
    .twob,
    .twoc {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
    .onebqb{
      font-size: 30px;
    }
  }